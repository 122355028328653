import { render, staticRenderFns } from "./EditGDoc.vue?vue&type=template&id=761ae930&scoped=true&"
import script from "./EditGDoc.vue?vue&type=script&lang=js&"
export * from "./EditGDoc.vue?vue&type=script&lang=js&"
import style0 from "./EditGDoc.vue?vue&type=style&index=0&id=761ae930&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "761ae930",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VExpandXTransition,VHover,VIcon,VProgressCircular,VRow})
